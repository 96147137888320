import "styles/pages/page-meal.scss";
import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "components/Breadcrumbs";

const PageMeal = ({ pageContext }) => {
	const pageMeal = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/jedzenie.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Jedzenie" />
			<section className="kitchen">
				<div className="container">
					<h2 className="section-title">{pageMeal.foodMainTitle}</h2>
					<div className="row">
						<div className="col-xl-6">
							<div
								className="kitchen__text"
								dangerouslySetInnerHTML={{
									__html: pageMeal.foodMainDesc,
								}}
							></div>
							<img
								src={pageMeal.foodMainImg2?.sourceUrl}
								alt=""
								className="img-fluid kitchen__img-1"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageMeal.foodMainImg1?.sourceUrl}
								alt=""
								className="img-fluid kitchen__img-3"
							/>
							<img
								src={pageMeal.foodMainImg3?.sourceUrl}
								alt=""
								className="img-fluid kitchen__img-2"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="meal-main">
				<div className="container">
					<h2 className="section-title">{pageMeal.mealsMainTitle}</h2>
				</div>
			</section>

			{pageMeal.meals.map((node, index) => {
				return (
					<section
						className={
							index % 2 === 0 ? "meals meals--reverse" : "meals"
						}
						key={index}
					>
						<div className="container">
							<div className="row">
								<div className="col-xl-5 offset-xl-1">
									<h3 className="subsection-title">
										{node.mealsTitle}
									</h3>
									<TextWithSymbol
										title={node.mealsIngredients}
										desc={node.mealsIngredientsDesc}
									/>
									<TextWithSymbol
										title={node.mealsTaste}
										desc={node.mealsTasteDesc}
									/>
								</div>
								<div className="col-xl-5 offset-xl-1 text-center">
									<img
										src={node.mealsImage?.sourceUrl}
										alt=""
										className="img-fluid"
									/>
								</div>
							</div>
						</div>
					</section>
				);
			})}
		</Layout>
	);
};

export default PageMeal;
